<template>
  <div class="mcr-half-column-images">
    <img
      v-for="image in imagesData"
      :key="image.id"
      :src="image.previewSrc"
      :data-src="image.src"
      class="lazyload"
      @click="onClick(image)"
    />
  </div>
</template>

<script>
import {getGalleryImageData, getImageLazyLoadPlaceholderSrc, getImageSrc} from '@common/utils/utils';

export default {
  props: {
    imagesStr: String,
  },
  data() {
    return {
      splitTag: '【mcr】',
    };
  },
  computed: {
    imagesData() {
      return this.imagesStr
        .split(this.splitTag)
        .slice(0, 2)
        .map((image, index) => {
          return {previewSrc: getImageLazyLoadPlaceholderSrc(image), src: getImageSrc(image), id: index};
        });
    },
  },
  methods: {
    onClick(image) {
      let data = this.imagesData.map(item => {
        return getGalleryImageData(item.id, item.src, '');
      });
      this.$store.commit('setGalleryItemsState', data);
      this.$store.dispatch('displayGalleryAction', image.id);
    },
  },
};
</script>
<style scoped lang="scss">
.mcr-half-column-images {
  margin: 40px 0px;
  display: flex;
  justify-content: center;

  img {
    max-width: calc(50% - 19px);
    clear: none;
    float: left;
    display: block;

    &:first-child {
      margin-right: 30px;
    }
  }

  &::after {
    content: '';
    clear: both;
    display: table;
  }

  img {
    border: var(--report-gallery-border);
    box-shadow: var(--report-gallery-shadow);
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--report-image-hover-border-color);
    }
  }
}
</style>
